import { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import ContactForm from "../components/subComponents/ContactForm";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import service1 from "../Images/service/service1.jpg";
import service2 from "../Images/service/service2.jpg";
import service3 from "../Images/service/service3.jpg";
import service4 from "../Images/service/service4.jpg";
import service5 from "../Images/service/service5.png";
import service6 from "../Images/service/service6.jpg";
import BannerVideoMed from "../videos/MedexBanner.mp4";

import University from "../components/subComponents/University";
const Home = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);
   const [isLoading, setIsLoading] = useState(true);

   const handleIframeLoad = () => {
     setIsLoading(false);
   };

  // Function to toggle the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };


  // const toggleVideo = () => {
  //   setShowVideo(!showVideo);
  // };

  useEffect(() => {
    const handleResize = () => {
      // Get the width of the window
      const windowWidth = window.innerWidth;
      // Set isPlaying to false when the window width is less than 768px
      setIsPlaying(windowWidth >= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize on component mount
    handleResize();

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const images = [
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
  ];
  const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div>
      {/* Banner Start */}
      <div className="header-carousel position-relative">
        <div className="header-carousel-item ">
          <img
            src="img/MED-1.jpg"
            className="img-fluid w-100 d-md-block d-none"
            alt="Medex"
          />
          <img
            src="img/MED-2.jpg"
            className="img-fluid w-100 d-md-none d-block"
            alt="Medex"
          />

          <div className="carousel-caption">
            <div className="carousel-caption-content p-3">
              <div
                className="section-title  wow fadeInUp"
                data-wow-delay="0.1s"
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* Banner End */}

      {/* banner video starts */}
      <section
        className=" experience position-relative overflow-hidden"
        id="service"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {/*<div className="about-img">
      <div className="video h-100">
        {!showVideo && (
          <img
            src="img/carousel-2.jpg"
            className="rounded w-100 h-100"
            alt="video image"
          />
        )}
        {!showVideo && (
          <button
            type="button"
            className="btn btn-play"
            onClick={toggleVideo}
          >
            <span></span>
          </button>
        )}
        {showVideo && (
          <div className="embed-responsive embed-responsive-16by9 w-100 h-100">
            <iframe
              className="embed-responsive-item"
              title="Medex Education"
              width="100%"
              height="350"
              src="video/Tech_video.mp4"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
    </div>*/}
              <div className="about-img  ">
                <div className="video">
                  <img
                    src="img/videoImg.jpg"
                    className=" rounded w-100  "
                    alt=" MEDEX"
                  />
                  <button
                    type="button"
                    className="btn btn-play"
                    onClick={toggleModal}
                  >
                    <span></span>
                  </button>
                </div>

                {/* Video Modal */}
                {showModal && (
                  // <div
                  //   className="modal fade show"
                  //   tabIndex="-1"
                  //   style={{ display: "block" }}
                  // >
                  //   <div className="modal-dialog modal-dialog-centered modal-lg">
                  //     <div className="modal-content">
                  //       <div className="modal-body">
                  //         <button
                  //           type="button"
                  //           className="btn-close"
                  //           onClick={toggleModal}
                  //         ></button>
                  //         <div className="embed-responsive embed-responsive-16by9">
                  //           <iframe
                  //             className="embed-responsive-item responsive-iframe"
                  //             title="Medex Education"
                  //             width="100%"
                  //             src={BannerVideoMed}
                  //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  //             allowFullScreen
                  //           ></iframe>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  <div
                    className="modal fade show"
                    tabIndex="-1"
                    style={{ display: "block" }}
                  >
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                      <div className="modal-content">
                        <div className="modal-body position-relative">
                          <button
                            type="button"
                            className="btn-close"
                            onClick={toggleModal}
                          ></button>
                          {isLoading && (
                            <div className="loader-container">
                              <div className="loader"></div>
                            </div>
                          )}
                          <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                              className="embed-responsive-item responsive-iframe"
                              title="Medex Education"
                              width="100%"
                              // height="550"
                              src={BannerVideoMed}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                              onLoad={handleIframeLoad}
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* End Video Modal */}
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute top-0 start-0 end-0">
          <div className="bg-white py-2 py-md-9 py-xl-10" />
        </div>
      </section>
      {/* banner video ends */}

      {/* Home Start */}
      <div className="container-fluid py-2  service">
        <div className="container-fluid feature ">
          <div className="container py-5">
            <div className="section-title  wow fadeInUp" data-wow-delay="0.1s">
              <div className="sub-style">
                <h2 className="sub-title  mb-0">Medex Education</h2>
              </div>
              <h2 className="mb-0 pt-3">
                At Medex , we understand that the journey to becoming a
                successful medical professional is both challenging and
                rewarding. That's why we're here to offer comprehensive and
                personalized support every step of the way.
              </h2>
            </div>
          </div>
          {/* Who We Are Starts here  */}
          <div className="container-fluid about bg-light py-5">
            <div className="container py-5">
              <div className="row g-5 align-items-center">
                <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0.2s">
                  <div className="about-img pb-5 ps-5">
                    <img
                      src="img/abouty.jpeg"
                      className="img-fluid rounded w-100"
                      style={{ objectFit: "cover" }}
                      alt="Medex"
                    />

                    <div className="about-experience ">
                      <span className="mx-2"> MEDEX EDUCATION</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 wow fadeInRight" data-wow-delay="0.4s">
                  <div className="section-title text-start mb-5">
                    <h4 className="sub-title pe-3 mb-0">Who We Are</h4>
                    <p className="mb-4">
                      Medex education is a leading medical education consultancy
                      led by medical professionals , dedicated to empowering
                      aspiring healthcare professionals to achieve their
                      academic and career goals. With years of experience and a
                      team of seasoned experts, we specialize in providing
                      tailored guidance and resources to help individuals
                      navigate the complex landscape of medical education.
                    </p>
                    <h4 className="sub-title pe-3 mb-0">Our Mission</h4>
                    <p className="mb-4">
                      Our mission is simple yet profound: to inspire, guide, and
                      support aspiring medical professionals as they embark on
                      their educational journey. We believe that every
                      individual deserves access to quality education and
                      mentorship, and we are committed to providing unparalleled
                      support to help them reach their full potential.
                    </p>
                    <h4 className="sub-title pe-3 mb-0">What We Offer</h4>
                    <p className="mb-4">
                      At Medex , we offer a wide range of services designed to
                      meet the diverse needs of our clients. From academic
                      advising and exam preparation to residency placement
                      assistance and career counseling, we provide comprehensive
                      support at every stage of the medical education process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Who We Are ends here  */}
        </div>
      </div>

      {/* Home End */}
      {/* Aboutus Start */}
      <div
        className="container-fluid testimonial py-2 wow zoomInDown"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="section-title mb-2">
            <div className="sub-style">
              <h2 className="sub-title text-white px-3 mb-0">About Us</h2>
            </div>
            <h2 className=" mb-4">Leading Medical Innovation</h2>
          </div>
          <div className="testimonial-carousel ">
            <div className="testimonial-item">
              <div className="testimonial-inner p-3">
                <p className="text-white fs-7">
                  At Medex, we believe that the journey of a healthcare
                  professional is marked by continuous learning, growth, and
                  excellence. As doctors ourselves, we understand the challenges
                  and opportunities inherent in the pursuit of medical education
                  and career advancement. That's why we are passionate about
                  providing comprehensive guidance and support to aspiring
                  medical students at every stage of their journey.
                </p>

                <div className="text-center">
                  <NavLink to={"/about"}>
                    <div className="btn btn-primary rounded-pill text-white py-3 px-5 bg-dark">
                      Know More
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Aboutus End */}
      {/* Why CHoose Us */}
      <div className="container-fluid feature ">
        <div className="container py-5">
          <div className="section-title  wow fadeInUp" data-wow-delay="0.1s">
            <div className="sub-style">
              <h2 className="sub-title px-3 mb-3">Why Choose Us</h2>
            </div>
            <h4 className="mb-0 py-4">
              Choosing Medex means choosing excellence, integrity, and
              personalized attention. With our team of dedicated medical
              professionals by your side, you can be assured that you will
              receive the guidance, support, and resources you need to succeed
              in your medical career.
            </h4>
          </div>
        </div>
      </div>

      {/* Universities Start */}
      <University />
      {/* Service starts */}
      <div
        className="container-fluid testimonial py-5 "
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="section-title mb-4">
            <div className="sub-style">
              <h4 className="sub-title text-white px-3 mb-0">OUR SERVICES</h4>
            </div>
          </div>
          <OwlCarousel
            className="testimonial-carousel owl-carousel "
            {...options}
          >
            {images.map((image, index) => (
              <div className="testimonial-item" key={index}>
                <div className=" ">
                  <div className="">
                    <img src={image} className="img-fluid" alt="Testimonial" />
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
          <div className="text-center mt-4">
            <NavLink to={"/service"}>
              <div className="btn btn-primary rounded-pill text-white py-3 px-5 bg-dark">
                Know More
              </div>
            </NavLink>
          </div>
        </div>
      </div>

      {/* Contact */}
      <ContactForm />

      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
    </div>
  );
};

export default Home;
