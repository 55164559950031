import React from "react";
import { NavLink } from "react-router-dom";
import uni1 from '../../Images/university/seu/IMG_5147.jpeg'
import uni2 from "../../Images/university/Caucasus uni/IMG_5177.jpg";
import uni3 from "../../Images/university/uni of georgia/IMG_5161.jpg";
import uni4 from "../../Images/university/european uni/IMG_5152.jpeg";
import uni5 from "../../Images/university/tma/tma1.jpg";
import uni6 from "../../Images/university/new vision/IMG_5175.jpg";
import uni7 from "../../Images/university/tsmu/IMG_5170.jpg";
import uni8 from "../../Images/university/tsu/IMG_5181.jpg";
import uni9 from "../../Images/university/david/IMG_5137.jpeg";
import uni10 from "../../Images/university/caucasus internat ciu/IMG_5156.jpeg";

const universities = [
  {
    name: "NEW VISION UNIVERSITY (NVU)",
    image: uni6,
  },
  {
    name: "TBILISI STATE MEDICAL UNIVERSITY (TSMU)",
    image: uni7,
  },
  {
    name: "TBILISI STATE UNIVERSITY (TSU)",
    image: uni8,
  },

  {
    name: "GEORGIAN NATIONAL UNIVERSITY (SEU)",
    image: uni1,
  },

  {
    name: "CAUCASUS UNIVERSITY (CU)",
    image: uni2,
  },
  {
    name: "PETRE SHOTAZE TBILISI MEDICAL ACADEMY (TMA)",
    image: uni5,
  },

  {
    name: "UNIVERSITY OF GEORGIA (UG)",
    image: uni3,
  },
  {
    name: "EUROPEAN UNIVERSITY (EU)",
    image: uni4,
  },

  {
    name: "CAUCASUS INTERNATIONAL UNIVERSITY (CIU)",
    image: uni10,
  },
  {
    name: "DAVID TVILDIANI MEDICAL UNIVERSITY (DTMU)",
    image: uni9,
  },
];
const University = () => {
  return (
    <div>
      <div className="container-fluid team pb-5">
        <div className="container ">
          <div
            className="section-title mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="sub-style">
              <h2 className="sub-title px-3 mb-0">Universities</h2>
            </div>
          </div>
          <div className="row g-4 justify-content-center ">
            {universities.map((itms, index) => (
              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item rounded">
                  <div className="team-img rounded-top h-100">
                    <img
                      src={itms.image}
                      className="img-fluid rounded-top w-100"
                      alt="university"
                      style={{ height: "260px" }}
                    />
                    <div className="team-icon d-flex justify-content-center">
                      <NavLink to={"/university"}>
                        <div className="btn btn-primary rounded-pill text-white py-3 px-5 bg-dark">
                          Details
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="team-content text-center border border-primary border-top-0 rounded-bottom p-4">
                    <h5>{itms.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default University;
