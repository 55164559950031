import React from "react";

const Footer = () => {
  return (
    <div>
      <div
        className="container-fluid footer py-5 wow fadeIn"
        data-wow-delay="0.2s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="footer-item d-flex flex-column ">
                <h4 className="text-white mb-4">Medex</h4>
                <p>
                  At the heart of our mission lies the aspiration to nurture
                  future healers. Join us in shaping the next generation of
                  compassionate physicians
                </p>
                <div className="d-flex align-items-center">
                  <i className="fas fa-share fa-2x text-white me-2" />
                  <a
                    className="btn-square btn btn-primary text-white rounded-circle mx-1"
                    href="https://www.facebook.com/profile.php?id=61560197304668"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn-square btn btn-primary text-white rounded-circle mx-1"
                    href="https://wa.me/+995568006336"
                  >
                    <i className="fab fa-whatsapp" />
                  </a>
                  <a
                    className="btn-square btn btn-primary text-white rounded-circle mx-1"
                    href="https://www.instagram.com/medex_education/"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    className="btn-square btn btn-primary text-white rounded-circle mx-1"
                    href="https://www.youtube.com/channel/UCNHyJBl5PwxTLQdstyy_N6A"
                  >
                    <i className="fab fa-youtube" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 col-xl-6 ">
              <div className="footer-item d-flex flex-column ">
                <h4 className="mb-4 text-white">Contact Info</h4>
                <a href="/">
                  <i className="fa fa-map-marker-alt me-2" />
                  Georgia
                </a>

                <a href="mailto: medexeducation.ge@gmail.com">
                  <i className="fas fa-envelope me-2" />{" "}
                  medexeducation.ge@gmail.com
                </a>
                <a href="tel:+995 599738314">
                  <i className="fas fa-phone me-2" />
                  +995 599738314
                </a>
                <a href="tel:+995 568006336">
                  <i className="fas fa-phone me-2" />
                  +995 568006336
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright Start */}
      <div className="container-fluid bg-dark copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-12 text-center  mb-md-0">
              <span className="text-white">
                <i className="fas fa-copyright text-light me-2" /> Copyright
                <a href="/" className="mx-1">
                  Medex
                </a>
                , All right reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}
    </div>
  );
};

export default Footer;
