import React from "react";

const TopHead = () => {
  return (
    <div>
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0 align-items-center" style={{ height: 45 }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="/" className="text-light me-4">
                <i className="fas fa-map-marker-alt text-primary me-2" />
                Georgia
              </a>
              <a href="tel:+995 599738314" className="text-light me-4">
                <i className="fas fa-phone-alt text-primary me-2" />
                +995 599738314
              </a>

              <a
                href="mailto:medexeducation.ge@gmail.com
              "
                className="text-light me-0"
              >
                <i className="fas fa-envelope text-primary me-2" />
                medexeducation.ge@gmail.com
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default TopHead;
