import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";

const Navbar = () => {
  // const [active, setactive] = useState("/");

  // const handleSetactive = (path) => {
  //   setactive(path);
  // };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // Sticky Navbar
    const handleScroll = () => {
      if ($(window).scrollTop() > 45) {
        $(".navbar").addClass("sticky-top shadow-sm");
      } else {
        $(".navbar").removeClass("sticky-top shadow-sm");
      }
    };
    $(window).on("scroll", handleScroll);

    // Back to top button
    const handleBackToTop = () => {
      if ($(window).scrollTop() > 300) {
        $(".back-to-top").fadeIn("slow");
      } else {
        $(".back-to-top").fadeIn("slow");
      }
    };
    $(window).on("scroll", handleBackToTop);

    // Cleanup
    return () => {
      $(window).off("scroll", handleScroll);
      $(window).off("scroll", handleBackToTop);
    };
  }, []);

  return (
    <div className="container-fluid position-relative p-0">
      <nav className="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
        <NavLink to="/" className="navbar-brand p-0">
          <div className="navbar-brand">
            <img
              src="img/logo-medex.png"
              className="logo img-fluid"
              alt="logo"
              style={{ width: "50px" }}
            />
          </div>
        </NavLink>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarCollapse"
        >
          <div className="navbar-nav ms-auto py-0">
            <NavLink
              to={"/"}
              className="nav-item nav-link"
              activeClassName="active"
              onClick={() => setIsOpen(false)}
            >
              Home
            </NavLink>
            <NavLink
              to={"/about"}
              className="nav-item nav-link"
              activeClassName="active"
              onClick={() => setIsOpen(false)}
            >
              About
            </NavLink>
            <NavLink
              to={"/service"}
              className="nav-item nav-link"
              activeClassName="active"
              onClick={() => setIsOpen(false)}
            >
              Services
            </NavLink>
            <NavLink
              to={"/university"}
              className="nav-item nav-link"
              activeClassName="active"
              onClick={() => setIsOpen(false)}
            >
              University
            </NavLink>
          </div>
          <NavLink
            to={"/contact"}
            activeClassName="active"
              onClick={() => setIsOpen(false)}

            className="nav-item nav-link btn btn-primary rounded-pill text-white py-2 px-4 flex-wrap flex-sm-shrink-0"
          >
            Enquire Now
          </NavLink>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
