import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Service from "./routes/Service";
import Navbar from "./components/navbar/Navbar";
import Spinner from "./components/Spinner";
import TopHead from "./components/navbar/TopHead";
import WOW from "wowjs";
import "animate.css";
import { useEffect, useState } from "react";
import FloatingIcons from "./components/FloatingIcons";
import ContactUs from "./routes/ContactUs";
import NotFound from "./routes/NotFound";
import { ScrollToTopOnPageChange } from "./components/ScrollToTopOnPageChange";
import University from "./routes/University";
function App() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const location = useLocation();
  const [loading, setLoading] = useState(false); // Initially set to false

  useEffect(() => {
    setLoading(true); // Set loading to true when location changes

    const spinnerTimeout = setTimeout(() => {
      setLoading(false); // Set loading to false after a delay
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(spinnerTimeout);
  }, [location]); // Re-trigger effect when location changes
  return (
    <div className="overflow-hidden">
      {loading && <Spinner />}
      <TopHead />
      <Navbar />
      <Routes>
        <Route exact path="/" element=<Home /> />
        <Route path="/about" element=<About /> />
        <Route path="/service" element=<Service /> />
        <Route path="/contact" element=<ContactUs /> />
        <Route path="/university" element=<University /> />
        <Route path="/*" element=<NotFound /> />
      </Routes>
      <FloatingIcons />
      <ScrollToTopOnPageChange />
    </div>
  );
}

export default App;
