import React from "react";
import { Carousel } from "react-bootstrap";

import service1 from "../Images/service/service1.jpg";
import service2 from "../Images/service/service2.jpg";
import service3 from "../Images/service/service3.jpg";
import service4 from "../Images/service/service4.jpg";
import service5 from "../Images/service/service5.png";
import service6 from "../Images/service/service6.jpg";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import Brudcrubs from "../components/Brudcrubs";
import Footer from "../components/Footer/Footer";
const Service = () => {
  const images = [
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
  ];
  const medicalServices = [
    {
      title: "Career Guidance",
      sentence:
        "Avail free counseling for a successful medical career trajectory.",
      icon: "fas fa-user-md",
    },
    {
      title: "Visa Support",
      sentence:
        "Get comprehensive assistance for securing student visas hassle-free.",
      icon: "fas fa-duotone fa-passport",
    },
    {
      title: "Residency Assistance",
      sentence: "Navigate residency card procedures with expert assistance.",
      icon: "fas fa-hospital-user",
    },

    {
      title: "Admission Ease",
      sentence: "Simplify the admission process for a hassle-free experience.",
      icon: "fas fa-solid fa-graduation-cap",
    },
    {
      title: "Quick Documentation",
      sentence:
        "Expedite documentation and admission letter processing for faster outcomes.",
      icon: "fas fa-file",
    },

    {
      title: "Travel and Campus Aid",
      sentence:
        "Receive support with travel arrangements and campus-related needs.",
      icon: "fas fa-solid fa-plane-departure",
    },
    {
      title: "University Match",
      sentence:
        "Assist in selecting universities tailored to students' interests and aspirations.",
      icon: "fas fa-duotone fa-school",
    },
    {
      title: "Information Hub",
      sentence:
        "Access accurate and reliable information essential for medical education decisions.",
      icon: "fas fa-users",
    },
    {
      title: "Continuous Support",
      sentence:
        "Receive ongoing support throughout your entire course duration.",
      icon: "fas fa-solid fa-phone-volume",
    },
    {
      title: "Informed Decisions",
      sentence:
        "Make budget-conscious and  informed choices with expert guidance.",
      icon: "fas fa-check",
    },
    {
      title: "Student Card Help",
      sentence:
        "Get guidance and support for student travel card requirements and application.",
      icon: "fas fa-id-card",
    },
    {
      title: "Accommodation Assistance",
      sentence:
        "Ensure comfortable student living with tailored accommodation assistance.",
      icon: "fas fa-briefcase-medical",
    },
  ];

  return (
    <div>
      <Brudcrubs PageName="Services" />
      {/* Header End */}

      {/* slider Start */}
      <div
        className="container-fluid  py-3 wow zoomInDown"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <Carousel
            interval={3000}
            prevIcon={<SlArrowLeft size={30} color="var(--bs-primary)" />}
            nextIcon={<SlArrowRight size={30} color="var(--bs-primary)" />}
          >
            {images.map((src, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 carousel-image carousel-image"
                  src={src}
                  alt={`Slide ${index + 1}`}
                />
                {/* <Carousel.Caption>
                  <h3 className="">Our Services</h3>
                </Carousel.Caption> */}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      {/* slider ends */}

      {/* Services Why choose */}
      <div className="container-fluid feature">
        <div className="container py-3">
          <div
            className="section-title mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="sub-style">
              <h4 className="sub-title px-3 mb-0">
                Make Your Dream into Reality with Us{" "}
              </h4>
            </div>
            <h1 className="display-3 mb-4">Start Your Medical Journey Today</h1>
            <p className="mb-0">
              Unlock your future in medicine. We guide you through admissions.
              Maximize your chances of admission with expert guidance. Take the
              first step towards becoming a doctor. Make a difference in the
              world. Start your medical career with confidence. Let's pave the
              way for your success in medicine Together.
            </p>
          </div>
          <div className="row g-4 justify-content-center">
            {medicalServices.map((item, index) => (
              <div
                className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="row-cols-1 feature-item p-4">
                  <div className="col-12">
                    <div className="feature-icon mb-4">
                      <div className="p-3 d-inline-flex bg-white rounded">
                        <i className={`${item.icon} fa-4x text-primary `} />
                        
                      </div>
                    </div>
                    <div className="feature-content d-flex flex-column">
                      <h5 className="mb-4">{item.title}</h5>
                      <p className="mb-4">{item.sentence}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-4">
      <Footer />
      </div>
    </div>
  );
};

export default Service;
